//
//
//
//
//
//

import HomePage from '@/modules/Homepage/index.vue'
export default {
  components: { HomePage },
  layout: 'default'
}
