//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import { storyService } from '@/services'
import { size } from 'lodash'
import moment from 'moment'
import ListStory from '@/components/Homepage/ListStory/index.vue'
import HotAuthor from '@/components/Homepage/HotAuthor/index.vue'
// import Thumbnail from '@/components/Homepage/Thumbnail/index.vue'
export default {
  components: { ListStory, HotAuthor },
  data() {
    return {
      // listHotStory: [],
      // listNewStory: [],
      // listFullStory: [],
      loadingHotStory: false,
      loadingNewStory: false,
      loadingFullStory: false,
      listNewUpdate: [],
      metaKeywords: this.$t('metaKeywords'),
      webName: process.env.webName
    }
  },
  head() {
    return {
      title: `${process.env.webName} - ${this.$t('metaTitle')}`,
      meta: [
        {
          name: 'keywords',
          property: 'keywords',
          content: this.$t('metaKeywords'),
        },
        {
          name: 'description',
          property: 'description',
          content: this.$t('metaDescription'),
        },
        {
          name: 'og:description',
          property: 'og:description',
          content: this.$t('metaDescription'),
        },
        {
          property: 'og:title',
          name: 'og:title',
          content: `${process.env.webName} - ${this.$t('metaTitle')}`,
        },
        {
          property: 'og:site_name',
          name: 'og:site_name',
          content: `${process.env.webName} - ${this.$t('metaTitle')}`,
        },
        {
          property: 'og:image',
          name: 'og:image',
          content: require(`~/assets/images/homepage/${process.env.webName}.png`),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      listCategory: (state) => state.listCategory || [],
      listHotStory: (state) => state.listHotStory || [],
      listNewStory: (state) => state.listNewStory || [],
      listFullStory: (state) => state.listFullStory || [],
    }),
  },
  mounted() {
    if (!size(this.listCategory)) {
      this.getListCategory()
    }
    if (!size(this.listHotStory)) {
      this.getListStoryByType('hot')
    }
    if (!size(this.listNewStory)) {
      this.getListStoryByType('new')
    }
    if (!size(this.listFullStory)) {
      this.getListStoryByType('full')
    }
    this.getNewStoryUpdate()
    // const emptyData = [this.getNewStoryUpdate()]

    // if (!size(this.listCategory)) {
    //   emptyData.push(this.listCategory())
    // }
    // if (!size(this.listHotStory)) {
    //   emptyData.push(this.getListStoryByType('hot'))
    // }
    // if (!size(this.listNewStory)) {
    //   emptyData.push(this.getListStoryByType('new'))
    // }
    // if (!size(this.listFullStory)) {
    //   emptyData.push(this.getListStoryByType('full'))
    // }
    // if (size(emptyData)) {
    //   Promise.all(emptyData);
    // }
  },

  methods: {
    ...mapMutations({
      saveListCategory: 'saveListCategory',
      saveListHotStory: 'saveListHotStory',
      saveListFullStory: 'saveListFullStory',
      saveListNewStory: 'saveListNewStory',
    }),
    async getListCategory() {
      try {
        const response = await storyService.getListCategory(
          this.$route.params.slug
        )
        if (response.status) {
          this.saveListCategory(response.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getListStoryByType(type) {
      try {
        switch (type) {
          case 'hot':
            this.loadingHotStory = true
            break
          case 'new':
            this.loadingNewStory = true
            break
          case 'full':
            this.loadingFullStory = true
            break
          default:
        }
        const response = await storyService.getListStoryByType(type)
        if (response.status) {
          const totalStory = type === 'hot' ? 12 : 8
          const books = response.data.books.data.slice(0, totalStory)
          switch (type) {
            case 'hot':
              this.saveListHotStory(books)
              break
            case 'new':
              this.saveListNewStory(books)
              break
            case 'full':
              this.saveListFullStory(books)
              break
            default:
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        switch (type) {
          case 'hot':
            this.loadingHotStory = false
            break
          case 'new':
            this.loadingNewStory = false
            break
          case 'full':
            this.loadingFullStory = false
            break
          default:
        }
      }
    },
    async getNewStoryUpdate() {
      try {
        const reqParam = {
          page: 0,
          limit: 10,
        }
        const response = await storyService.getNewStoryUpdate(reqParam)
        if (response.status) {
          this.listNewUpdate = response.data?.books.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).fromNow()
    },
  },
}
