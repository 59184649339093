//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    col: {
      type: String,
      default: '',
    },
    stories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    formatStatus(status) {
      if (status === 'updating') {
        return 'UP'
      }
      if (status === 'full') {
        return 'FULL'
      }
    },
    numberFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K';
      } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
      } else {
        return num;
      }
    },
  },
}
