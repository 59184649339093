//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import { storyService } from '@/services'
import { size } from 'lodash';
export default {
  props: ['cols'],

  mounted () {
    if (!size(this.listAuthorize)) {
      this.getListAuthor()
    }
  },

  computed: {
    ...mapState({
      listAuthorize: (state) => state.listAuthorize || []
    })
  },

  methods: {
    ...mapMutations({
      saveListAuthorize: 'saveListAuthorize'
    }),
    async getListAuthor () {
      const response = await storyService.getListAuthor()
      if (response.status) {
        this.saveListAuthorize(response.data.slice(0, 10))
      }
    }
  }
}
