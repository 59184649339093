//
//
//
//

import HotNovel from './hotnovelpub.vue'
import newhomepage from './newhomepage.vue'

export default {
  components: {
    HotNovel,
    newhomepage,
  },

  data() {
    return {
      webName: process.env.webName,
    }
  },
}
